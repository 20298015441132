import React from "react";

import { getAnalytics } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  Navigation,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

// import { IconButton, Tooltip } from "@mui/material";
import { GridViewRounded } from "@mui/icons-material";

import { firebaseConfig } from "../firebase_config";
import logo from "./images/ninja_logo.svg";
import { textSearchController } from "./text_search";

import {
  homeSchema
} from "./schemas/home_schema";

import {
  ngoSchema,
} from "./schemas/ngo_schema";

import {
  blogSchema,
} from "./schemas/blog_schema";

import {
  faqSchema,
} from "./schemas/faq_schema";

import "typeface-rubik";
import "typeface-space-mono";

function Main() {

  const homeCollection = buildCollection({
    path: "about",
    schema: homeSchema,
    name: "About",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "title",
      "intro",
      "videoLink",
      "image1",
      "image2",
      "created_at",
      "online",
    ],
    description: "Frontpage",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const ngoCollection = buildCollection({
    path: "ngo",
    schema: ngoSchema,
    name: "Ngo",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "logo",
      "title",
      "description",
      "linkWebsite",
      "linkDonation",
      "jobOffer",
      "created_at",
      "online",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const blogCollection = buildCollection({
    path: "blog",
    schema: blogSchema,
    name: "Blog",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "author",
      "featured_image",
      "title",
      "short_intro",
      "article",
      "gallery",
      "created_at",
      "online",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const faqCollection = buildCollection({
    path: "faq",
    schema: faqSchema,
    name: "FAQ",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "question",
      "answer",
      "created_at",
      "online",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const recruitmentCollection = buildCollection({
    path: "recruitment",
    schema: faqSchema,
    name: "Recruitment",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "question",
      "answer",
      "created_at",
      "online",
      "gallery",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const networkAnimationCollection = buildCollection({
    path: "network-animation",
    schema: faqSchema,
    name: "Network Animation",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "question",
      "answer",
      "created_at",
      "online",
      "gallery",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const callForProjectsCollection = buildCollection({
    path: "call-for-projects",
    schema: faqSchema,
    name: "Call for projects",
    group: "Single page",
    defaultSize: "l",
    properties: [
      "question",
      "answer",
      "created_at",
      "online",
      "gallery",
    ],
    description: "",
    textSearchEnabled: true,
    initialFilter: {
      // status: ["==", "published"],
    },
  });

  const onFirebaseInit = (config: Object) => {
    // Just calling analytics enables screen tracking
    getAnalytics();
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    if (user?.email?.includes("flanders")) {
      throw Error("Stupid Flanders!");
    }

    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field
    const sampleUserData = await Promise.resolve({
      roles: ["admin"],
    });

    authController.setExtra(sampleUserData);
    console.log("Allowing access to", user);
    return true;
  };

  const navigation: NavigationBuilder<FirebaseUser> = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    if (authController.extra)
      console.log(
        "Custom data stored in the authController",
        authController.extra
      );

    const navigation: Navigation = {
      collections: [homeCollection, ngoCollection, blogCollection, faqCollection, recruitmentCollection, networkAnimationCollection, callForProjectsCollection],
    };

    return navigation;
  };

  return (
    <FirebaseCMSApp
      name={<><span style={{ top: '5px', position: 'relative' }}><GridViewRounded /></span> Dashboard</>}
      authentication={myAuthenticator}
      signInOptions={["password"]}
      textSearchController={textSearchController}
      allowSkipLogin={false}
      logo={logo}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
      onFirebaseInit={onFirebaseInit}
      // toolbarExtraWidget={githubLink}
      loginViewProps={{
        noUserComponent: <>Email doesn't exist.</>,
        displaySignupScreen: false,
      }}
    />
  );
}

export default Main;
