import { buildProperty, buildSchema } from "@camberi/firecms";
import { BlogEntryPreview } from "../custom_schema_view/BlogEntryPreview";

type BlogEntry = {
    created_at: Date;
    short_intro: string;
    gallery: any[];
    featured_image: string;
    online: boolean;
    author: string;
    title: string,
    article: string,
};

export const blogSchema = buildSchema<BlogEntry>({
    name: "Blog entry",
    views: [
        {
            path: "preview",
            name: "Preview",
            builder: (props) => <BlogEntryPreview {...props} />,
        },
    ],
    properties: {
        author: buildProperty({
            title: "Author",
            validation: { required: true },
            dataType: "string",
        }),
        featured_image: buildProperty({
            title: "Featured image",
            dataType: "string",
            validation: { required: true, url: true },
            config: {
                storageMeta: {
                    storeUrl: true,
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000",
                    },
                },
            },
        }),
        title: buildProperty({
            title: "Title",
            validation: { required: true },
            dataType: "string",
        }),
        short_intro: buildProperty({
            title: "Short intro",
            validation: { required: true },
            dataType: "string",
        }),
        article: {
            title: "Article",
            dataType: "string",
            config: {
                markdown: true
            }
        },
        gallery: {
            title: "Gallery",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    storageMeta: {
                        storeUrl: true,
                        mediaType: "image",
                        storagePath: "images",
                        acceptedFiles: ["image/*"],
                        metadata: {
                            cacheControl: "max-age=1000000",
                        },
                    },
                },
            },
        },
        created_at: {
            title: "Created at",
            dataType: "timestamp",
            autoValue: "on_create",
        },
        online: buildProperty({
            title: "Online",
            dataType: "boolean",
        }),
    },
    defaultValues: {
        status: "draft",
        tags: ["default tag"],
    },
});