import { buildProperty, buildSchema } from "@camberi/firecms";
import { BlogEntryPreview } from "../custom_schema_view/BlogEntryPreview";

type HomeEntry = {
    intro: string;
    created_at: Date;
    image1: string;
    image2: string;
    online: boolean;
    title: string;
    videoLink: string;
};

export const homeSchema = buildSchema<HomeEntry>({
    name: "Home entry",
    views: [
        {
            path: "preview",
            name: "Preview",
            builder: (props) => <BlogEntryPreview {...props} />,
        },
    ],
    properties: {
        title: buildProperty({
            title: "Title",
            validation: { required: true },
            dataType: "string",
        }),
        intro: buildProperty({
            title: "Article",
            dataType: "string",
            config: {
                markdown: true
            }
        }),
        videoLink: buildProperty({
            title: "Video link",
            validation: { required: false },
            dataType: "string",
        }),
        image1: buildProperty({
            title: "Image 1",
            dataType: "string",
            validation: { required: true, url: true },
            config: {
                storageMeta: {
                    storeUrl: true,
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000",
                    },
                },
            },
        }),
        image2: buildProperty({
            title: "Image 2",
            dataType: "string",
            validation: { required: true, url: true },
            config: {
                storageMeta: {
                    storeUrl: true,
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000",
                    },
                },
            },
        }),
        created_at: {
            title: "Created at",
            dataType: "timestamp",
            autoValue: "on_create",
        },
        online: buildProperty({
            title: "Online",
            dataType: "boolean",
        }),
    },
    defaultValues: {
        status: "draft",
        tags: ["default tag"],
    },
});
