// Replace with your Firebase config
export const firebaseConfig = {
    apiKey: "AIzaSyDdpY94P9ujh4X5Q-W_ilZuyvzusBVqnvw",
    authDomain: "french-ngo.firebaseapp.com",
    projectId: "french-ngo",
    storageBucket: "french-ngo.appspot.com",
    messagingSenderId: "597004876764",
    appId: "1:597004876764:web:88a892cdaf33f0b99ded19",
    measurementId: "G-FN407YS4GP"
};
