import { buildProperty, buildSchema } from "@camberi/firecms";
import { BlogEntryPreview } from "../custom_schema_view/BlogEntryPreview";

type NgoEntry = {
    created_at: Date;
    logo: string;
    online: boolean;
    title: string;
    linkWebsite: string;
    linkDonation: string;
    jobOffer: string;
    description: string;
};

export const ngoSchema = buildSchema<NgoEntry>({
    name: "NGO entry",
    views: [
        {
            path: "preview",
            name: "Preview",
            builder: (props) => <BlogEntryPreview {...props} />,
        },
    ],
    properties: {
        logo: buildProperty({
            title: "Logo",
            dataType: "string",
            validation: { required: true, url: true },
            config: {
                storageMeta: {
                    storeUrl: true,
                    mediaType: "image",
                    storagePath: "images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000",
                    },
                },
            },
        }),
        title: buildProperty({
            title: "Title",
            validation: { required: true },
            dataType: "string",
        }),
        description: buildProperty({
            title: "Description",
            validation: { required: true },
            dataType: "string",
        }),
        linkWebsite: buildProperty({
            title: "Website",
            validation: { required: true },
            dataType: "string",
        }),
        linkDonation: buildProperty({
            title: "Donation",
            validation: { required: true },
            dataType: "string",
        }),
        jobOffer: buildProperty({
            title: "Job Offer",
            validation: { required: true },
            dataType: "string",
        }),
        created_at: {
            title: "Created at",
            dataType: "timestamp",
            autoValue: "on_create",
        },
        online: buildProperty({
            title: "Online",
            dataType: "boolean",
        }),
    },
    defaultValues: {
        status: "draft",
        tags: ["default tag"],
    },
});