import { buildProperty, buildSchema } from "@camberi/firecms";
import { BlogEntryPreview } from "../custom_schema_view/BlogEntryPreview";

type FaqEntry = {
    created_at: Date;
    online: boolean;
    question: string;
    answer: string;
    gallery: any[];
};

export const faqSchema = buildSchema<FaqEntry>({
    name: "Entry",
    views: [
        {
            path: "preview",
            name: "Preview",
            builder: (props) => <BlogEntryPreview {...props} />,
        },
    ],
    properties: {
        question: buildProperty({
            title: "Question",
            validation: { required: true },
            dataType: "string",
        }),
        answer: buildProperty({
            title: "Answer",
            validation: { required: true },
            dataType: "string",
            config: {
                markdown: true
            }
        }),
        gallery: {
            title: "Gallery",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    storageMeta: {
                        storeUrl: true,
                        mediaType: "image",
                        storagePath: "images",
                        acceptedFiles: ["image/*"],
                        metadata: {
                            cacheControl: "max-age=1000000",
                        },
                    },
                },
            },
        },
        created_at: {
            title: "Created at",
            dataType: "timestamp",
            autoValue: "on_create",
        },
        online: buildProperty({
            title: "Online",
            dataType: "boolean",
        }),
    },
    defaultValues: {
        status: "draft",
        tags: ["default tag"],
    },
});